/** blue custom color **/
$color : #4785b9;

.button-v3 {
  min-width: 114px;
  height: 30px;
  border-radius: 5px;
  margin-right: 10px;
  &-primary {
    background-color: white;
    border: solid 1px $color;
    color : $color;
    font-family: montserratregular;
    font-size: 10px;
  }
  &-danger {
    background-color: white;
    border: solid 1px #d0021b;
    font-family: montserratregular;
    font-size: 10px;
  }
  &-danger-red{
    background-color: #d0021b;
    border: solid 1px #d0021b;
    font-family: montserratregular;
    color : white;
    font-size: 10px;
  }
  &-not-primary {
    background-color: $color;
    border: solid 1px $color;
    color : white;
    font-family: montserratregular;
    font-size: 10px;
  }
  &-warning {
    background-color: white;
    border: solid 1px #ff6600;
    color : #ff6600;
    font-family: montserratregular;
    font-size: 10px;
  }
}

.invoice-chkbox {
  &-container {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &-checkmark {
    border: solid 1px #979797;
    border-radius: 2px;
    position: absolute;
    top: -1;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: white;
  }
  &-container input:checked ~ .invoice-chkbox-checkmark {
    border: solid 1px $color !important;
    background-color: $color
  }
  &-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  &-container input:checked ~ .invoice-chkbox-checkmark:after {
    display: block;
  }
  &-container .invoice-chkbox-checkmark:after {
    left: 3px;
    top: -1px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.button-long {
  padding-left: 13px;
  padding-right: 13px;
}

.popup-background {
  position: absolute !important;
  z-index: 4;
  transition: .3s ease-in-out;
  opacity: 1;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  background: rgba(22, 22, 22, 0.7);
  padding: 100px;
  transition: .3s ease-in-out;
  width: 100%;
  min-height: 200%;
}

.manual-popup {
  top:40px;
  position: absolute !important;
  left: 380px;
  z-index: 5;
  pointer-events: all;
  transition: .3s ease-in-out;
  width: 760px;
  min-height: 300px;
  max-height: 1200px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.update-faktur-popup {
  top:40px;
  position: absolute !important;
  left: 380px;
  z-index: 5;
  pointer-events: all;
  transition: .3s ease-in-out;
  width: 398px;
  min-height: 321px;
  max-height: 1000px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.absolute-right-header {
  position: absolute;
  right: 10px;
  top: 25px;
  transform: translateY(-50%);
}

.right-footer {
  float:right;;
  margin-top: 10px;
  padding-bottom: 10px;
}

.manual {
  &-table-ungenerated{
    border: solid 1px #e2e2e2;
  }
  &-panel-header {
    padding:0.75rem 1rem;
    border-bottom: 1px solid #f1f1f1;
    font-family: montserratregular;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  &-panel-content {
    padding:0.75rem 1rem;
    max-height:600px;
  }
  &-panel-footer {
    padding:0.75rem 1rem;
    // border-top: 1px solid #f1f1f1;
    font-family: montserratregular;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &-select-box {
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    padding-left: 16px;
    padding-top: 7px;
    padding-bottom: 8px;
    margin:10px 0;
  }
  &-form-title {
    text-transform: uppercase;
    margin-top:10px;
    font-family: montserratregular;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
  }
  &-input-text {
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    padding-left: 16px;
    padding-top: 7px;
    padding-bottom: 8px;
    margin:10px 0;
  }
  &-table-striped {
    margin: 0;
    max-height: 130px;
    border-radius: 5px;
    background-color: #ffffff;
    border-collapse: separate;
    width: 100%;
    font-size: 12px;
    font-family: montserratregular;
    color: rgb(74, 74, 74);
  }
   &-table-striped tr {
     position: relative;
     border-collapse:separate;
     border-spacing:0;
     background-position-x: 10px;
     padding:10px 0;
     transition:background .1s;
     height:45px;
   }

   &-table-striper table thead td {

   }

   &-table-striped > tbody > tr:nth-of-type(odd)
   {
     background-color:#fafafa;
   }

   &-table-striped > tbody > tr > td {
     font-family: hind_regularregular;
     white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
     white-space: -webkit-pre-wrap; /*Chrome & Safari */
     white-space: -pre-wrap;      /* Opera 4-6 */
     white-space: -o-pre-wrap;    /* Opera 7 */
     white-space: pre-wrap;       /* css-3 */
     word-wrap: break-word;       /* Internet Explorer 5.5+ */
     word-break: break-all;
     white-space: normal;
   }

   &-table-striped thead tr > td {
     z-index: 7;
     /* border-top: 1px solid #ddd; */
     border-bottom: 1px solid #ebebeb;
   }

   &-table-striped thead tr td:first-child {

   }
   &-table-striped thead tr td:last-child {

   }

   &-table-striped tbody tr td:first-child {

   }
   &-table-striped tbody tr td:last-child {
      text-align: right;
   }

   &-table-striped tr:hover:nth-of-type(even) {
     // background-color: #ffffff !important;
   }
   &-table-striped th {
   border-top: none;
   }
   &-table-striped td:first-child{
     padding-left: 15px;
   }
   &-table-striped td:last-child{
   }
   &-table-striped td{
     position: relative;
     border-spacing: 0px;
     padding: 10px;
   }
   &-no-result {
      // width: 454px;
      height: 129px;
      border-radius: 5px;
      background-color: #ffffff;
      border: dotted 1px #e2e2e2;
      border-width: 2px;
      margin-bottom: 30px;
    }
    &-no-result-text {
      text-align: center;
      vertical-align: middle;
      line-height: 129px;
      font-family: montserratregular;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #9b9b9b;
    }
}

.generate-result-content {
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  -ms-transition: max-height 0.3s;
  -o-transition: max-height 0.3s;
  transition: max-height 0.3s ease-in;
  // overflow: hidden;
  // "height: 0" not work with css transitions
  // max-height: 0;
}
.generate-result-inner {
    &-scsf {

    }
    &-utility {
      overflow: auto;
      height: 139px;
    }
    &-inquiry {
      overflow: auto;
      height: 200px;
    }
}

.generated {
  // max-height: 200px;
  position: relative;
}

#invoiceDetail {

	textarea {
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		width:100%;
		padding:5px 10px;
		font-size: 12px !important;
	}

	.cover-input-box-receipt {
		color: #9b9b9b;
		width: 255px;
		min-height: 66px;
		border: 1px dashed #9b9b9b;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		cursor: pointer;
		font-size: 10px;
	}

	table.mdl-data-table {
		width: 100%;
		border: 0;
		tr {
		&:hover {
			background-color: inherit;
		}
		}
		td {
		text-align: left;
		border: 0;
		vertical-align: top;
		overflow: unset;
		}
	}
	.content-title {
		font-family: montserratregular;
		font-size: 10px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #999999;
		margin-bottom: 5px;
	}

	.content-detail {
		font-family: hind_regularregular;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #4a4a4a;
	}

	.button-billing {
		display: inline-block;
		text-transform: uppercase;
		font-weight: normal;
		font-size: 8px;
		text-align: center;
		cursor: pointer;
		padding: 1em 1.5em;
		color: #4a4a4a;
		border-right: solid 1px #f6f6f6;
		img {
		display: block;
		margin: 0 auto;
		}
  }
  
  .button-billing-print-invoice {
		display: inline-block;
		text-transform: uppercase;
		font-weight: normal;
		font-size: 8px;
		text-align: center;
		cursor: pointer;
		padding: 1em 1.5em;
		color: #4a4a4a;
		border: solid 1px #fcfcfc;
		img {
		display: block;
		margin: 0 auto;
    }
    background-color: #fcfcfc;
	}

	.warningText
	{
	font-family: montserratregular;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.58;
	letter-spacing: normal;
	color: #4a4a4a;
	}

	.warn
	{
	display: inline-block;
	}

	.warn:before {
		content: "!";
		display: inline-block;
		width: 1em;
		height: 1em;
		line-height: 1em;
		border-radius: 50%;
		color: #fff;
		background-color: #d0021b;
		text-align: center;
	}

	.payment-receipt {
		&-popup {
			padding:80px 260px;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
		}
		&-panel-content {
			padding: 0 20px 20px 20px;
			background-color: white;
			overflow: auto;
    }
    &-panel-content-inquiry {
      height: 300px;
			padding: 0 20px 20px 20px;
			background-color: white;
			overflow: auto;
		}
		&-invoice-title {
			margin-top: 0;
			font-weight: bold;
			font-size: 14px;
		}
		&-action-page {
			background: white;
			font-size: 12px;
			font-family: "Hind", sans-serif;
		}
		&-page {
			border: 1px solid rgba(0, 0, 0, 0.1);
			padding: 20px 20px 0 20px;
			background: white;
			font-size: 12px;
			font-family: "Hind", sans-serif;
			border-radius: 5px;
		}
		&-table {
			margin-top: 5px;
			border-color: white;
		}
		&-no-border {
			border-top: none !important;
			border-bottom: none !important;
		}
		&-no-border-bottom {
			border-bottom: none !important;
		}
		&-bmt-align {
			vertical-align: bottom !important;
		}
		&-card-panel {
			border: 1px solid #979797;
			border-radius: 8px;
			margin-top: 14px;
		}
		&-card-panel-title {
			padding-left: 29px;
			padding-top: 10px;
			margin-bottom: 10px;
			font-weight: bold;
			font-size: 12px !important;
		}
		&-card-panel-header {
			border-bottom: 1px solid #979797;
			height: 40px;
			font-family: "Hind", sans-serif;
		}
		&-card-panel-content {
			padding-left: 38px;
			padding-right: 34px;
		}
		&-table-eo {
			width: 100%;
			border-left: 0px;
			font-size: 10px;
			font-family: "Hind", sans-serif;
			color: #4a4a4a;
		}
		&-page table {
			margin-top: 5px;
			margin-bottom: 5px;
			width: 100%;
		}
		&-footer {
			position: fixed;
			left: 0;
			bottom: 30px;
			width: 100%;
		}
		&-line {
			display: block;
			height: 1px;
			border: 0;
			border-top: 1px solid #ccc;
			padding: 0;
		}
  }
	.payment-reject {
    &-popup {
        padding:80px 390px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
		&-panel-content {
			padding: 0 20px 20px 20px;
			background-color: white;
			overflow: auto;
    }
    &-panel-content-inquiry {
      height: 300px;
			padding: 0 20px 20px 20px;
			background-color: white;
			overflow: auto;
		}
		&-invoice-title {
			margin-top: 0;
			font-weight: bold;
			font-size: 14px;
		}
		&-action-page {
			background: white;
			font-size: 12px;
			font-family: "Hind", sans-serif;
		}
		&-page {
			border: 1px solid rgba(0, 0, 0, 0.1);
			padding: 20px 20px 0 20px;
			background: white;
			font-size: 12px;
			font-family: "Hind", sans-serif;
			border-radius: 5px;
		}
		&-table {
			margin-top: 5px;
			border-color: white;
		}
		&-no-border {
			border-top: none !important;
			border-bottom: none !important;
		}
		&-no-border-bottom {
			border-bottom: none !important;
		}
		&-bmt-align {
			vertical-align: bottom !important;
		}
		&-card-panel {
			border: 1px solid #979797;
			border-radius: 8px;
			margin-top: 14px;
		}
		&-card-panel-title {
			padding-left: 29px;
			padding-top: 10px;
			margin-bottom: 10px;
			font-weight: bold;
			font-size: 12px !important;
		}
		&-card-panel-header {
			border-bottom: 1px solid #979797;
			height: 40px;
			font-family: "Hind", sans-serif;
		}
		&-card-panel-content {
			padding-left: 38px;
			padding-right: 34px;
		}
		&-table-eo {
			width: 100%;
			border-left: 0px;
			font-size: 10px;
			font-family: "Hind", sans-serif;
			color: #4a4a4a;
		}
		&-page table {
			margin-top: 5px;
			margin-bottom: 5px;
			width: 100%;
		}
		&-footer {
			position: fixed;
			left: 0;
			bottom: 30px;
			width: 100%;
		}
		&-line {
			display: block;
			height: 1px;
			border: 0;
			border-top: 1px solid #ccc;
			padding: 0;
		}
	}

	.confirm-payment {
		&-popup {
			padding:70px 390px;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
		}
		&-panel-header {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding: 12px 20px;
			background-color: white;
			border-bottom: 3px solid #f1f1f1;
			font-family: montserratregular;
			font-size: 14px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #6a6a6a;
		}
		&-panel-content {
			padding: 15px 8px;
			background-color: white;
			height: 385px;
			overflow: auto;
		}
		&-panel-footer {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			height: 72px;
			padding: 12px 20px;
			background-color: white;
			border-top: 1px solid #f1f1f1;
			font-family: montserratregular;
			font-size: 14px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}
		&-blue-text {
			font-family: montserratregular;
			font-size: 16px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #1c78bb;
		}
		&-receipt {
			width:100%;
			justify-content: normal;
			padding-left:16px;
			max-height: 200px
		}
		&-receipt-preview {
			width:100%;
			justify-content: center;
			padding-left:16px;
			max-height: 200px
		}
		&-preview {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&-preview img {
			max-width: 100%;
			max-height: 200px;
		}
	}

  .payment-history {
    &-popup {
      padding:70px 260px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
    &-panel-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 12px 20px;
      background-color: white;
      border-bottom: 3px solid #f1f1f1;
      font-family: montserratregular;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6a6a6a;
    }
    &-panel-content {
      padding: 5px 38px;
      background-color: white;
      height: 240px;
      overflow: auto;
    }
    &-panel-footer {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 52px;
      padding: 12px 20px;
      background-color: white;
      border-top: 1px solid #f1f1f1;
      font-family: montserratregular;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    &-blue-text {
      font-family: montserratregular;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #1c78bb;
    }
    &-receipt {
      width:100%;
      justify-content: normal;
      padding-left:16px;
      max-height: 200px
    }
    &-receipt-preview {
      width:100%;
      justify-content: center;
      padding-left:16px;
      max-height: 200px
    }
    &-preview {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-preview img {
      max-width: 100%;
      max-height: 200px;
    }
  }

}

.utility-action {
  &-popup {
    padding:70px 390px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  }
  &-popup-confirm{
    padding:70px 190px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  }
  &-panel-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 12px 20px;
    background-color: white;
    border-bottom: 3px solid #f1f1f1;
    font-family: montserratregular;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  &-panel-content {
    padding: 15px 8px;
    background-color: white;
    height: 214px;
    overflow: auto;
  }
  &-panel-footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 72px;
    padding: 12px 20px;
    background-color: white;
    border-top: 1px solid #f1f1f1;
    font-family: montserratregular;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &-blue-text {
    font-family: montserratregular;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1c78bb;
  }
  &-receipt {
    width:100%;
    justify-content: normal;
    padding-left:16px;
    max-height: 200px
  }
  &-receipt-preview {
    width:100%;
    justify-content: center;
    padding-left:16px;
    max-height: 200px
  }
  &-preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-preview img {
    max-width: 100%;
    max-height: 200px;
  }
}

.faktur-action {
  &-popup {
    padding:70px 561q  0px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  }  
  &-popup-confirm {
    padding:70px 491q  0px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  }
  &-panel-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 12px 20px;
    background-color: white;
    border-bottom: 3px solid #f1f1f1;
    font-family: montserratregular;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  &-panel-content {
    padding: 15px 8px;
    background-color: white;
    height: 190px;
    overflow: auto;
  }
  &-panel-footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 72px;
    padding: 12px 20px;
    background-color: white;
    border-top: 1px solid #f1f1f1;
    font-family: montserratregular;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &-blue-text {
    font-family: montserratregular;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1c78bb;
  }
  &-receipt {
    width:100%;
    justify-content: normal;
    padding-left:16px;
    max-height: 200px
  }
  &-receipt-preview {
    width:100%;
    justify-content: center;
    padding-left:16px;
    max-height: 200px
  }
  &-preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-preview img {
    max-width: 100%;
    max-height: 200px;
  }
}

.manual-title-result {
  margin-top: 12px;
  font-family: montserratregular;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.bulk-import {
  &-popup {
    padding:70px 390px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  }
  &-panel-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 12px 20px;
    background-color: white;
    border-bottom: 3px solid #f1f1f1;
    font-family: montserratregular;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  &-panel-content {
    padding: 15px 8px;
    background-color: white;
    height: 214px;
    overflow: auto;
  }
  &-panel-footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 72px;
    padding: 12px 20px;
    background-color: white;
    border-top: 1px solid #f1f1f1;
    font-family: montserratregular;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &-blue-text {
    font-family: montserratregular;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1c78bb;
  }
  &-receipt {
    width:100%;
    justify-content: normal;
    padding-left:16px;
    max-height: 200px
  }
  &-receipt-preview {
    width:100%;
    justify-content: center;
    padding-left:16px;
    max-height: 200px
  }
  &-preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-preview img {
    max-width: 100%;
    max-height: 200px;
  }
}

.title-td {
  border:none;
}
